<template>
  <div class="view">
    <ul class="contents">
      <template v-for="(content, i) in contentsFiltered">
        <li v-if="content.infos" :key="i">
          <div class="content" :class="{'-highlight': content.infos.primary, '-visual': visual}">
            <div class="content-title">{{ content.infos.title }}</div>
            <div class="content-description" v-html="content.infos.body"></div>
            <a v-if="content.infos.youtube_id" class="content-youtube" :href="content.infos.youtube_id" target="_blank"><i class="icon-video"></i>{{ $t('See the video') }}</a>
          </div>
          <div class="visual" v-if="i === 0 && visual">
            <img :src="content.infos.visual.src" :alt="content.infos.visual.alt">
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'Contents',
  props: ['contentsFiltered', 'contentsJson'],
  data() {
    return {
      visual: false
    }
  },
  watch: {
    contentsFiltered () {
      this.mountList()
    }
  },
  mounted () {
    if (this.contentsFiltered) {
      this.mountList()
    }
  },
  methods: {
    mountList() {

      if (this.contentsJson.length === this.contentsFiltered.length && this.contentsFiltered.filter((e) => e.global).length && this.contentsJson.length !== this.contentsFiltered.filter((e) => e.global).length) {
        this.$emit("filterContents", { event: null, type: 'global' });
      }

      if (this.contentsFiltered.length !== 0 && this.contentsFiltered[0].infos) {
        this.visual = this.contentsFiltered[0].infos.visual
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.view {
  padding: 0 4rem;
  @include bp-down(lg) {
    padding: 0 2rem;
  }
}

.contents {
  margin: 0;
  padding: 0;
  list-style: none;

  & > li {
    margin-bottom: 4rem;
    position: relative;

    @include bp-down(lg) {
      margin-bottom: 2rem;
    }
  }
}

.content {
  padding: 2rem;
  background: white;
  color: $primary;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);

  &.-visual {
    width: 65%;

    @include bp-down(lg) {
      width: 100%;
    }
  }

  &.-highlight {
    background: $primary;
    color: white;

    .content-description {
      color: white;
      ::v-deep {
        ul li:before {
          border-top: 1px solid white;
          border-right: 1px solid white;
        }
        ol li:before {
          color: white;
        }
      }
    }

    .content-youtube {
      background: white;
      color: $primary;
    }
  }

  &-title {
    display: block;
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: em(18);
    line-height: em(22, 18);
    text-transform: uppercase;
  }

  &-description {
    color: #1A1B1C;
    font-weight: 300;
    font-size: em(14);
    line-height: em(17, 14);
    margin-bottom: 2rem;

    ::v-deep {
      p {
        margin-bottom: 2rem;
      }

      img {
        margin-bottom: 2rem;
        max-width: 100%;
      }

      ul {
        padding: 0;
        margin-bottom: 2rem;
        list-style: none;

        li {
          position: relative;
          padding: 0 0 0 2rem;
          margin-bottom: 1rem;

          &:before {
            display: block;
            content: '';
            position: absolute;
            left: 0;
            top: .5rem;
            width: .6rem;
            height: .6rem;
            border-top: 1px solid $primary;
            border-right: 1px solid $primary;
            transform: rotate(45deg);
            transition: all 0.2s ease-in-out;
          }
        }
      }

      ol {
        padding: 0;
        margin-bottom: 2rem;
        list-style: none;
        counter-reset: section;

        li {
          position: relative;
          padding: 0 0 0 2rem;
          margin-bottom: 1rem;

          &:before {
            counter-increment: section;
            content: counter(section) '.';
            display: block;
            position: absolute;
            left: 0;
            top: .2rem;
            color: $primary;
            font-size: em(14);
            line-height: em(17, 14);
          }
        }
      }
    }
  }

  &-youtube {
    display: inline-block;
    background: $primary;
    font-size: em(12);
    line-height: em(24, 12);
    border-radius: 2.2rem;
    color: white;
    padding: 0 .8rem 0 .4rem;

    i {
      margin-right: .4rem;
      font-size: em(16, 12);
      vertical-align: middle;
      position: relative;
      top: -.1rem;
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      i {
        margin-right: .8rem;
      }
    }
  }
}

.visual {
  position: absolute;
  top: 0;
  right: 0;
  width: calc(35% - 4rem);

  @include bp-down(lg) {
    position: static;
    margin-top: 2rem;
    width: 100%;
  }

  img {
    width: 100%;
  }
}

</style>
